import React from 'react';
import './Skills.css';
import awsimg from "../images/amazonwebservices.png";
import aspnetimg from "../images/aspnet.png";
import bitbucketimg from "../images/bitbucket.png";
import cimg from "../images/c.png";
import cplusplusimg from "../images/cplusplus.png";
import csharpimg from "../images/csharp.png";
import cssimg from "../images/css3.png";
import gitimg from "../images/git.png";
import githubimg from "../images/github.png";
import htmlimg from "../images/html5.png";
import javascriptimg from "../images/javascript.png"
import mysqlimg from "../images/mysql.png";
import pythonimg from "../images/python.png";
import visualstudioimg from '../images/visualstudio.png';


export default class  Skills extends React.Component {
    render(){
        const img_list =[
            {src:cimg,alt:'C Icon by Icon Mafia'},
            {src:cplusplusimg, alt:'CplusplusIcon by Icon Mafia'},
            {src:pythonimg ,alt:'Python Icon by Icon Mafia'},
            {src:javascriptimg, alt:'Javascript Icon by Icon Mafia'},
            {src:htmlimg,alt:'HtmlIcon by Icon Mafia'},
            {src:cssimg,alt:'css3 Icon by Icon Mafia'},
            {src:mysqlimg, alt:'mysql Icon by Icon Mafia'},
            {src:gitimg, alt:'git Icon by Icon Mafia' },
            {src:aspnetimg, alt:'Aspnet core icon'},
            {src:awsimg, alt:'AmazonWebServices Icon by Icon Mafia' },
            {src:githubimg, alt:'Github Icon by Icon Mafia' },
            {src:bitbucketimg,alt:'Bitbucket Icon by Icon Mafia' },
            {src:csharpimg,alt:'Csharp Icon by Icon Mafia'},
            {src:visualstudioimg,alt:'Visualstudio Icon by Icon Mafia' }
        ]

        return (
            <section id="Skills">
             <h2>Skills</h2>
              <div className='skills_list'>
                {img_list.map((image,index) => (
                  <img key={index} id='skill_img' src={image.src} alt={image.alt}/>
                ))}
              </div>
            </section>
          );
    }
}