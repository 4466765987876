import React from 'react';
import './Home.css';

function HomePage() {
  return (
    <section id="Home">
      <div className="intro">
        <h1 className="intro-title">Hello World,</h1>
        <p className="intro-text">I am a Full Stack developer with experience in React, Python and other technologies. </p>
      </div>
    </section>
  );
}

export default HomePage;
