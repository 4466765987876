import React, { useEffect, useLayoutEffect } from 'react';
import projects_list from '../data';
import './Projects.css';

const Project = () => {
  const [contentLoaded, setContentLoaded] = React.useState(false);
  useEffect(() => {
    // Simulate content loading, replace with your actual loading logic
    setTimeout(() => {
      setContentLoaded(true);
    }, 1000);
  }, []); 

  useLayoutEffect(() => {
    const hash = window.location.hash;
    console.log('Hash:', hash);
    if (hash) {
      const encodedHash = encodeURIComponent(hash.substring(1));
      const element = document.getElementById(encodedHash);
      console.log('Element:', element);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, []);
  return (
    <div className='Project'>
      {Object.keys(projects_list).map((tech, index) => (
        <section className='project-section' key={index}>
          <h1 className='tech-name'>{tech}</h1>
          {projects_list[tech].map((project, projectIndex) => (
            <article className='project-article' id={project.title} key={projectIndex}>
              <h3 className='project-title'>{project.title}</h3>
              {
                project.title == 'Gesture Volume Control'?
                <video height='300px' width='600px' autoPlay loop>
                <source src={project.vid_url} type='video/mp4' />
              </video> : <img className='project-img'src={project.img_url}/>
              }
              <p className='tech-stack'>{project.tech_stack.join(', ')}</p>
              <a className='project-link' href={project.url} target='_blank' rel='noopener noreferrer'>
                View on GitHub
              </a>
            </article>
          ))}
        </section>
      ))}
    </div>
  );
};

export default Project;
