// Contact.js
import './Contact.css';
import { GitHub, Facebook, Linkedin, Instagram, Mail } from "react-feather";
export default function Contact() {
  return (
    <section id='Contact'>
      <div className='icons'>
        <a className="icon-circle github" href='https://github.com/dronv' target='_blank'>
          <GitHub className='social-logo github-logo' />
        </a>
        <a className="icon-circle linkedin" href='https://www.linkedin.com/in/dronvyas' target='_blank'>
          <Linkedin className='social-logo linkedin-logo' />
        </a>
        <a className="icon-circle facebook" href='https://www.facebook.com/dron.vyas.9' target='_blank'>
          <Facebook className='social-logo facebook-logo' />
        </a>
        <a className="icon-circle instagram" href='https://www.instagram.com/dron_vyas/' target='_blank'>
          <Instagram className='social-logo instagram-logo' />
        </a>
        <a className="icon-circle mail" href="mailto:dronvyas4454@gmail.com" target='_blank'>
          <Mail className='social-logo mail-logo' />
        </a>
      </div>
      <p style={{ 'color': '#0072b1' }}>&copy;2024 - Dron Vyas</p>
    </section>
  );
}
