import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="About" style={{ color: 'white' }}>
      <h1>Education</h1>
      <div className='Timeline'>
        <div className='TimelineItem'>
        <h3>Honours Bachelor of Science in Computer Science</h3>
        <p>Lakehead University | Jan 2019 - May 2023</p>
        <ul>
        <li>
          Relevant Courses: C Programming, C++ Programming, Data Structures, Operating Systems, Mobile Computing, 
          Database Management(SQL), Cloud Computing, Algorithm Design and Analysis, Web Health Informatics, Game Programming 
        </li>
        <li>Projects: Covid-19 Severity Prediction, Image Stitching using AWS </li>
        </ul>
        </div>
        <div className='TimelineItem'>
        <h3>Master of Science in Computer Science</h3>
        <p>Lakehead University | May 2023 - Aug 2024</p>
        <ul>
        <li>
          Relevant Courses: Big Data, Hardware Software Co-Design, Artificial Intelligence, Natural Language Processing(NLP)
        </li>
        <li>Projects: Amazon Product Analysis Big Data </li>
        </ul>
        </div>
      </div>
      <hr/>
      <h1>Experience</h1>
      <div className="Timeline">
        <div className="TimelineItem">
          <h3>QA Intern</h3>
          <p>Together Labs | June 2021 - Sept 2021</p>
          <ul>
            <li>
              Assisted with developing and executing automation test scripts for the backend APIs using the Karate Framework.
            </li>
            <li>
              Developed and executed manual test cases using the Insomnia app to test API endpoints, response codes, headers, payloads, and authentication.
            </li>
            <li>
              Learned about the software development lifecycle and the use of API testing tools for backend testing.
            </li>
          </ul>
        </div>
        <div className='TimelineItem'>
          <h3>QA Automation Intern</h3>
          <p>Together Labs | May 2022 - Sept 2022</p>
          <ul>
            <li>
            Developed and executed automated test scripts using the Playwright framework to ensure the quality of desktop and web applications.
            </li>
            <li>
            Assisted with other QA tasks as needed to support the overall quality of the software product.
            </li>
            <li>
            Participated in code reviews and provided feedback to improve code quality and Documented test cases using JIRA and Confluence.
            </li>
            <li>
            Utilized Git for version control and managed code repositories on Bitbucket.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default About;
