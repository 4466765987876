import platformerGame from "./images/2D_platformer.png";
const projects_list ={
  "React": [{
      title: 'HomeSync Web App',
      tech_stack:['ReactJs', 'NoSQL', 'Firebase', 'Authentication', 'CRUD'],
      url:'https://github.com/dronv/homesync',
      img_url:'https://private-user-images.githubusercontent.com/41694884/289208113-1a047ddb-12c3-4129-83c8-bb906c10732a.png?jwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJnaXRodWIuY29tIiwiYXVkIjoicmF3LmdpdGh1YnVzZXJjb250ZW50LmNvbSIsImtleSI6ImtleTEiLCJleHAiOjE3MDI2MDc3MjgsIm5iZiI6MTcwMjYwNzQyOCwicGF0aCI6Ii80MTY5NDg4NC8yODkyMDgxMTMtMWEwNDdkZGItMTJjMy00MTI5LTgzYzgtYmI5MDZjMTA3MzJhLnBuZz9YLUFtei1BbGdvcml0aG09QVdTNC1ITUFDLVNIQTI1NiZYLUFtei1DcmVkZW50aWFsPUFLSUFJV05KWUFYNENTVkVINTNBJTJGMjAyMzEyMTUlMkZ1cy1lYXN0LTElMkZzMyUyRmF3czRfcmVxdWVzdCZYLUFtei1EYXRlPTIwMjMxMjE1VDAyMzAyOFomWC1BbXotRXhwaXJlcz0zMDAmWC1BbXotU2lnbmF0dXJlPTQ3MDE4YjljOGE2NzgzNTM3ZmRmZjlhY2EwNWNmN2JiMTI2YmUwYmEzYjMwYWNkZWRjMWE2ZTNmNThhMGFmMjcmWC1BbXotU2lnbmVkSGVhZGVycz1ob3N0JmFjdG9yX2lkPTAma2V5X2lkPTAmcmVwb19pZD0wIn0.fWrw1p9Zhwwtrie1MfkVg112PIaNJI9KBmPqi-p39r8',
      year: 2023
    },
    {
      title:'Chatopia',
      tech_stack:['React Native', 'API', 'GPT-3.5'],
      url:'https://github.com/dronv/chatopia',
      img_url:'https://private-user-images.githubusercontent.com/41694884/256124595-b20007b1-11a0-4864-8573-bcd4f9a7d315.png?jwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJnaXRodWIuY29tIiwiYXVkIjoicmF3LmdpdGh1YnVzZXJjb250ZW50LmNvbSIsImtleSI6ImtleTEiLCJleHAiOjE3MDI2MDgyODYsIm5iZiI6MTcwMjYwNzk4NiwicGF0aCI6Ii80MTY5NDg4NC8yNTYxMjQ1OTUtYjIwMDA3YjEtMTFhMC00ODY0LTg1NzMtYmNkNGY5YTdkMzE1LnBuZz9YLUFtei1BbGdvcml0aG09QVdTNC1ITUFDLVNIQTI1NiZYLUFtei1DcmVkZW50aWFsPUFLSUFJV05KWUFYNENTVkVINTNBJTJGMjAyMzEyMTUlMkZ1cy1lYXN0LTElMkZzMyUyRmF3czRfcmVxdWVzdCZYLUFtei1EYXRlPTIwMjMxMjE1VDAyMzk0NlomWC1BbXotRXhwaXJlcz0zMDAmWC1BbXotU2lnbmF0dXJlPWE2NWRmZDJhNGZkNDJkNmRhZTAwNjk0ODVmNTY2Yzc4MGY5OGEyYTBkYjRkYTI2NjQ4NDgwOTkxOTYwOTgyYWEmWC1BbXotU2lnbmVkSGVhZGVycz1ob3N0JmFjdG9yX2lkPTAma2V5X2lkPTAmcmVwb19pZD0wIn0.gzwhPcvtwu6HG8VEHTGqvnXxtL7RbT98EFlyllPi5Og',
      year:2023
    },
    {
      title: 'Employee Directory Web App',
      tech_stack:['React', 'Amazon Web Services (AWS)'],
      url:'https://github.com/dronv/Employee-Directory-MyWorkForce',
      img_url:'https://user-images.githubusercontent.com/41694884/163874472-6216601e-efc0-4ec5-b22d-a6078a1aea44.PNG',
      year: 2022
    },
    {
      title: 'Movie Recommendation',
      tech_stack: ['React', 'API'],
      img_url:'https://private-user-images.githubusercontent.com/41694884/290971902-8751fceb-5908-40f2-8118-fa29e7ec05b6.png?jwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJnaXRodWIuY29tIiwiYXVkIjoicmF3LmdpdGh1YnVzZXJjb250ZW50LmNvbSIsImtleSI6ImtleTEiLCJleHAiOjE3MDI3MDEwOTAsIm5iZiI6MTcwMjcwMDc5MCwicGF0aCI6Ii80MTY5NDg4NC8yOTA5NzE5MDItODc1MWZjZWItNTkwOC00MGYyLTgxMTgtZmEyOWU3ZWMwNWI2LnBuZz9YLUFtei1BbGdvcml0aG09QVdTNC1ITUFDLVNIQTI1NiZYLUFtei1DcmVkZW50aWFsPUFLSUFJV05KWUFYNENTVkVINTNBJTJGMjAyMzEyMTYlMkZ1cy1lYXN0LTElMkZzMyUyRmF3czRfcmVxdWVzdCZYLUFtei1EYXRlPTIwMjMxMjE2VDA0MjYzMFomWC1BbXotRXhwaXJlcz0zMDAmWC1BbXotU2lnbmF0dXJlPTMzMGMwZWU2ZjliMWE5YjFlZjUyOWNhYWE3Nzg2OTU0MDRiYzQ3NDAzNTA3OTJmZjkzMDE4MjRhYmNjZjUwNDkmWC1BbXotU2lnbmVkSGVhZGVycz1ob3N0JmFjdG9yX2lkPTAma2V5X2lkPTAmcmVwb19pZD0wIn0.2g9Vmhh_76EOiN3MfiNYdv5HabuKBUlPgoQ7dKv3NzY',
      url:'https://github.com/dronv/Movie-recommender-react-app',
      year:2022,
    },
    {
      title: 'Food Recipe App',
      tech_stack:['React', 'API'],
      img_url:'https://private-user-images.githubusercontent.com/41694884/290971289-73436ffa-5150-46fe-ac89-343ea16da651.png?jwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJnaXRodWIuY29tIiwiYXVkIjoicmF3LmdpdGh1YnVzZXJjb250ZW50LmNvbSIsImtleSI6ImtleTEiLCJleHAiOjE3MDI3MDEwMjcsIm5iZiI6MTcwMjcwMDcyNywicGF0aCI6Ii80MTY5NDg4NC8yOTA5NzEyODktNzM0MzZmZmEtNTE1MC00NmZlLWFjODktMzQzZWExNmRhNjUxLnBuZz9YLUFtei1BbGdvcml0aG09QVdTNC1ITUFDLVNIQTI1NiZYLUFtei1DcmVkZW50aWFsPUFLSUFJV05KWUFYNENTVkVINTNBJTJGMjAyMzEyMTYlMkZ1cy1lYXN0LTElMkZzMyUyRmF3czRfcmVxdWVzdCZYLUFtei1EYXRlPTIwMjMxMjE2VDA0MjUyN1omWC1BbXotRXhwaXJlcz0zMDAmWC1BbXotU2lnbmF0dXJlPTlhM2NiZjkzMjI1MTU2NzEwNzk0NTc1YTI1NmFkN2JjZTczNWI0YjJlOWI1NmUzY2Q1NzVkYmMyMjdiZmJmZDcmWC1BbXotU2lnbmVkSGVhZGVycz1ob3N0JmFjdG9yX2lkPTAma2V5X2lkPTAmcmVwb19pZD0wIn0.X3HrpQdmgVFBeQNX6c1cFRhVl4FF2lTW_I2DLKjQEJg',
      url:'https://github.com/dronv/Food-Recipe-Web-App',
      year:2021
    },
    {
      title:'Weather App',
      tech_stack:['React','API'],
      url:"https://weatherappbydron.herokuapp.com/",
      img_url: 'https://user-images.githubusercontent.com/41694884/113502178-9656c000-94f8-11eb-802a-a11205406b9e.PNG',
      year: 2021
    }
    ],
    "Python":[
    {
      title: 'Amazon Product Analysis',
      tech_stack:['Big Data', 'Pyspark', 'Spark', 'Hadoop','HDFS'],
      url:'https://github.com/dronv/big-data-amazon-product-analysis',
      img_url:'https://private-user-images.githubusercontent.com/41694884/287115669-44c06bae-a840-4ef0-8b61-ef04d70e0cc5.png?jwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJnaXRodWIuY29tIiwiYXVkIjoicmF3LmdpdGh1YnVzZXJjb250ZW50LmNvbSIsImtleSI6ImtleTEiLCJleHAiOjE3MDI2MDg1ODMsIm5iZiI6MTcwMjYwODI4MywicGF0aCI6Ii80MTY5NDg4NC8yODcxMTU2NjktNDRjMDZiYWUtYTg0MC00ZWYwLThiNjEtZWYwNGQ3MGUwY2M1LnBuZz9YLUFtei1BbGdvcml0aG09QVdTNC1ITUFDLVNIQTI1NiZYLUFtei1DcmVkZW50aWFsPUFLSUFJV05KWUFYNENTVkVINTNBJTJGMjAyMzEyMTUlMkZ1cy1lYXN0LTElMkZzMyUyRmF3czRfcmVxdWVzdCZYLUFtei1EYXRlPTIwMjMxMjE1VDAyNDQ0M1omWC1BbXotRXhwaXJlcz0zMDAmWC1BbXotU2lnbmF0dXJlPWIwNGQ4YWNmZDE0MWY0ZWM4ZjhkZDU2NDAyOTg3YzY3MGQxZTI0N2EwZmQzNTRiNmZmYTJjOWMyNjIzMTE2MWQmWC1BbXotU2lnbmVkSGVhZGVycz1ob3N0JmFjdG9yX2lkPTAma2V5X2lkPTAmcmVwb19pZD0wIn0.3SWFfZAacFG-UFcZHgdd3Oyo9tdZxSfroX3dzSMxTAs',
      year: 2023
    },
    {
      title:'Gesture Volume Control',
      tech_stack:['Python'],
      url:"",
      vid_url:'https://github.com/dronv/gesture-volume-control/assets/41694884/114f1e48-e127-4143-86e0-9112f2e73d89',
      year:2023
    },
    {
      title: 'Covid-19 Risk Prediction Web Application',
      tech_stack:['Machine Learning', 'Python', 'Flask'],
      url:'https://github.com/dronv/covid-19-severity-prediction',
      img_url:'https://user-images.githubusercontent.com/41694884/231362384-7034bcfd-ef49-490b-957e-5b36a48fc632.png',
      year: 2023
    },
    {
      title: 'Honey Production Prediction',
      tech_stack:['Machine Learning', 'Python' , 'Jupyter Notebook'],
      url:'https://github.com/dronv/dronv-Machine-learning-Honey-Production-Codecademy',
      year: 2022
    }
    ],
    "Gaming":[
      {
        title: '2D Platformer Game',
        tech_stack:['Unity', 'C#'],
        img_url:platformerGame,
        url:'https://github.com/dronv/project2-platformer-game',
        year: 2023
      },

      {
        title: 'Memory Game',
        tech_stack:['Unity', 'C#'],
        img_url:"https://private-user-images.githubusercontent.com/41694884/291695925-d303f6ad-1f6f-4e19-ba5b-791bd9feff8f.png?jwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJnaXRodWIuY29tIiwiYXVkIjoicmF3LmdpdGh1YnVzZXJjb250ZW50LmNvbSIsImtleSI6ImtleTEiLCJleHAiOjE3MDMwMTUxNjMsIm5iZiI6MTcwMzAxNDg2MywicGF0aCI6Ii80MTY5NDg4NC8yOTE2OTU5MjUtZDMwM2Y2YWQtMWY2Zi00ZTE5LWJhNWItNzkxYmQ5ZmVmZjhmLnBuZz9YLUFtei1BbGdvcml0aG09QVdTNC1ITUFDLVNIQTI1NiZYLUFtei1DcmVkZW50aWFsPUFLSUFJV05KWUFYNENTVkVINTNBJTJGMjAyMzEyMTklMkZ1cy1lYXN0LTElMkZzMyUyRmF3czRfcmVxdWVzdCZYLUFtei1EYXRlPTIwMjMxMjE5VDE5NDEwM1omWC1BbXotRXhwaXJlcz0zMDAmWC1BbXotU2lnbmF0dXJlPTIwMjc4MGQ3NzQzMGFhNzU2OWMwOTZlYTRjYTlhZTIyYjhlNTM1ZGU3MTkwZWY0NWJlYjMwZGY0NjgxNWE5YjQmWC1BbXotU2lnbmVkSGVhZGVycz1ob3N0JmFjdG9yX2lkPTAma2V5X2lkPTAmcmVwb19pZD0wIn0.nn9nmzaDZvaybEA_82QXoek26EVIlX-s_Rhxj7dTnjM",
        url:'https://github.com/dronv/memory-game-unity',
        year: 2023
      },
      {
        title: 'Snake Game',
        tech_stack:['HaxeFlixel', 'Haxe'],
        url:'https://github.com/dronv/snake-game',
        year: 2023
      }
    ],
    "C#":[  {
      title: 'Resume Template',
      tech_stack:['ASP.NET Core', 'C#'],
      url:'https://github.com/dronv/resume-template-asp-net-core',
      year: 2023
    }]
  }
export default projects_list;