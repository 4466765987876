import React from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import Home from './components/Home';
import Project from './components/Projects';
import About from './components/About';
import Skills from './components/Skills';
import Contact from './components/Contact';
import projects_list from './data';
import './App.css';

const App = () => {
  return (
    <div className="App">
      <Router>
        <nav>
          <ul>
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li className='project'>
              <NavLink to="/projects">Projects</NavLink>
              <div className='project-sub-container'>
                {Object.keys(projects_list).map(category => (
                  <ul className='project-tech' key={category}>
                    <div>{category}</div>
                    {projects_list[category].map(project => (
                      <li key={project.title}>
                        <NavLink to={`/projects/#${project.title}`}>
                          {project.title}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                ))}
              </div>
            </li>
            <li className='about'>
              <NavLink to="/about">About</NavLink>
              <div className='About-sub-container'>
                    <ul className='about-list'>
                    <li>
                    <NavLink to={`/about/#Education`}>
                    Education
                    </NavLink>
                    </li>
                    <li>
                    <NavLink to={`/about/#Experience`}>
                    Experience
                    </NavLink>
                    </li>
                    </ul>
              </div>
            </li>
            <li>
              <NavLink to="/contact">Contact</NavLink>
            </li>
          </ul>
        </nav>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Project />} />
          <Route path="/about" element={<About />} />
          <Route path="/skills" element={<Skills />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
